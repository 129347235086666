<template>
	<div class="search-dropdown">
		<v2-search-input v-model="searchValue" @click.native="openList">
			<template #icon>
				<icon
					class="search-dropdown__icon"
					:class="{closed: !isOpen}"
					icon="v2-chevron-down"
					colour="transparent" />
			</template>
		</v2-search-input>
		<div class="search-dropdown__tags">
			<div v-for="tag in tags" :key="tag.id" class="tag">
				{{ tag.label }}
				<icon
					@click.native="onSelect(tag.id)"
					class="tag-icon"
					icon="v2-cross"
					colour="transparent" />
			</div>

		</div>
		<div class="search-dropdown__list" v-if="isOpen" v-click-outside="closeList">
			<div class="overflow-wrapper">
				<template v-if="filteredOptions.length">
					<div
						v-for="option in filteredOptions"
						:key="option.id"
						class="search-dropdown__list-item"
						@click="onSelect(option.id)">
						<div class="search-dropdown__list-item__status">
							<div v-if="checkSelect(option.id)" class="selected" />
						</div>
						<div class="search-dropdown__list-item__text">
							{{ option.label }}
						</div>
					</div>
				</template>

				<div v-else class="search-dropdown__list-item">
					<div class="search-dropdown__list-item__status" />
					<div class="search-dropdown__list-item__text">
						The list is empty
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Icon         from '@/components/ui/Icon';
	import V2SearchInput from '@/components/v2/ui/V2SearchInput.vue';
	export default {
		components: {
			V2SearchInput,
			Icon
		},

		props: {
			options: {
				type: Array,
				required: true
			},
			value: {
				type: Array,
				required: true
			}
		},

		data () {
			return {
				searchValue: '',
				isOpen: false
			};
		},

		computed: {
			filteredOptions () {
				return this.options.filter(({ label }) => label.includes(this.searchValue));
			},

			tags () {
				return this.options.filter(({ id }) => this.value.includes(id));
			}
		},

		methods: {
			openList () {
				this.isOpen = true;
			},

			closeList () {
				this.isOpen = false;
			},

			onSelect (id) {
        if (this.checkSelect(id)) {
          this.$emit('input', [...this.value].filter((itemId) => itemId !== id));
        } else {
          this.$emit('input', [...this.value, id]);
        }
			},

			checkSelect (id) {
				return !!this.value.includes(id);
			}
		}

	};
</script>

<style lang="scss" scoped>
.search-dropdown {
  position: relative;

  &__icon {
    transition: all 0.3s ease-in-out;
    transform: rotate(0deg);

    &.closed {
      transform: rotate(180deg);
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 6px;

    .tag {
      border-radius: 2px;
      padding: 3px 6px;
      background: $c-gray-l;
      display: flex;
      align-items: center;
      gap: 6px;

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  &__list {
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    max-height: 190px;
    padding: 8px 8px 8px 4px;
    background: $c-white-l;
    z-index: 3;
    border-radius: 4px;

    .overflow-wrapper {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      max-height: 174px;
    }

    &-item {
      display: flex;
      gap: 3px;
      width: calc(100% - 25px);
      cursor: pointer;

      &__status {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .selected {
          width: 5px;
          height: 5px;
          background: $c-dark-navy;
          border-radius: 100px;
        }
      }

      &__text {
        @include font(14px, $c-dark-navy, 700, 18px);
        width: 100%;
      }

      &:not(:last-child) {
        .search-dropdown__list-item__text {
          padding-bottom: 6px;
          border-bottom: 1px solid $c-bg-white
        }
      }

      &:not(:first-child) {
        .search-dropdown__list-item__text {
          padding-top: 6px;
        }
      }
    }
  }
}
</style>
